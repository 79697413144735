import React, { useEffect } from "react"
import Layout from "../components/layout"
import MaintenanceTitle from "../components/_titles/_maintenance_title"
import maintenanceStyles from "../styles/pages/maintenance/_maintenance.module.scss"
import QuestionButton from "../components/_maintenance/question_button"
import QuestionInput from "../components/_maintenance/question_input"
import { ButtonSemicircle } from "../components/buttons/_button_semicircle"
import { useCurrenDeviceMaintenance } from "../hooks/useCurrentDeviceMaintenance"
import useForm from "../hooks/useForm"
import { isNullOrUndefined } from "util"
import validate from "../components/_maintenance/maintenanceFormValidationRules"
import { ButtonArrow } from "../components/buttons/_btn_arrow"
import { TYPES, POSITION } from "../components/buttons/_btn_arrow"
import { navigate } from "gatsby"
import underlinedStyle from "../styles/_components/_maintenance/_input_underlined.module.scss"
import axios from 'axios'


const Maintenance = () => {
  
  const API_PATH = process.env.GATSBY_CALOREX_CONTACT_ENDPOINT

  const steps = {
    0: "Paso 1 de 2",
    1: "Paso 2 de 2"
  }

  const services = [
    {
      uid: 1,
      value: "SOLICITUD DE CAPACITACIÓN",
    },
    {
      uid: 2,
      value: "QUIERO SER DISTRIBUIDOR",
    },
    {
      uid: 3,
      value: "SUGERENCIAS",
    },
  ]

  const myInputs = {
    servicio: services[0],
    nombre: "",
    apellidos: "",
    calle: "",
    num_ext: "",
    num_int: "",
    colonia: "",
    ciudad: "",
    estado: "",
    codigo_postal: "",
    email: "",
    phone: "",
    marca: "Calorex",
    mensaje: "",
  }

  const fillViewInputs = () => {
    for (let key in inputs) {
      const element = document.getElementById(key)
      if (!isNullOrUndefined(element)) {
        element.value = inputs[key]
      }
    }
  }

  const onStep = true

  const [device] = useCurrenDeviceMaintenance(fillViewInputs)
  const { inputs, handleInputChange, handleSubmit, errors, step, handleNextStep, handleBackStep } = useForm(formValidCallback, myInputs, validate, onStep)


  useEffect(() => {
    //clases extra para mantenimiento
    document.getElementsByClassName("stage")[0].classList.add("stage__maintenance")
    document.getElementsByClassName("main")[0].classList.add("main__maintenance")
    document.querySelector("footer").style.display = "none"
    
    return () => {
      document.querySelector("footer").style.display = "block"
      document.getElementsByClassName("stage")[0].classList.remove("stage__maintenance")
      document.getElementsByClassName("main")[0].classList.remove("main__maintenance")
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    //Método que llena los inputs previamente llenos
    fillViewInputs()
    setStepClass()
    // eslint-disable-next-line
  }, [step])

  function setStepClass() {
    if (step === null) return

    const stage = document.getElementsByClassName("stage")[0]
    const maintenanceContainer = document.getElementsByClassName(maintenanceStyles.maintenance__container)[0]

    if (stage !== null && stage !== undefined) {
        stage.classList.add("stage__maintenance_step1")
      }
      if (maintenanceContainer !== null && maintenanceContainer !== undefined) {
        maintenanceContainer.classList.add(maintenanceStyles.maintenance__container_step1)
      }
    
  }

  function getStepText(step) {
    if (steps.hasOwnProperty(step)) {
      return steps[step]
    }
  }

  function handlerClickSend() {
    document.getElementById("submit__button").click()
  }

  function formValidCallback() {
    if(inputs.ciudad === "" || inputs.estado === ""|| inputs.codigo_postal === ""|| inputs.email === ""|| inputs.phone === "" || inputs.mensaje === "") return

    // console.log('es valido el formulario', inputs) 
    axios.post(API_PATH, inputs).then(function (response) {
     // console.log(response);
      navigate("/enviado")
    })
    .catch(function (error) {
      // console.log(error);
    })
  }


  function getQuestions() {
        switch (step) {
          case 0:
            switch (device) {
              case "mobile":
                return (
                  <>
                    <div className={maintenanceStyles.step1__container}>
                      <QuestionButton _classh3="service__question_title" isEnergyDropdown={true} _onChange={handleInputChange} inputs={inputs} name="servicio" dropdownList={services} question="Solicito contacto para:" required={true} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Mi nombre es:" placeholder="Miguel Angel" name="nombre" required={true} error={errors.nombre} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Mis apellidos son:" placeholder="Medina Calvillo" name="apellidos" required={true} error={errors.apellidos} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Vivo en la calle:" placeholder="Miguel Hidalgo" name="calle" required={true} error={errors.calle} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Número exterior:" placeholder="1534" name="num_ext" required={true} error={errors.num_ext} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Número interior:" placeholder="15" name="num_int" error={errors.num_int} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Colonia:" placeholder="Independencia" name="colonia" required={true} error={errors.colonia} />
                    </div>
                  </>
                )
              case "tablet":
                return (
                  <>
                    <div className={maintenanceStyles.step1__container}>
                      <QuestionButton _onChange={handleInputChange} inputs={inputs} name="servicio" dropdownList={services} question="Solicito contacto para:" required={true} />     
                      <div className={maintenanceStyles.several_questions_flex}>
                        <QuestionInput question="Mi nombre es" _onChange={handleInputChange} inputs={inputs} placeholder="Miguel Angel" name="nombre" required={true} error={errors.nombre} />
                        <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="y apellidos" _onChange={handleInputChange} inputs={inputs} placeholder="Medina Calvillo" name="apellidos" required={true} error={errors.apellidos} />
                      </div>
                      <div className={maintenanceStyles.several_questions_flex}>
                        <QuestionInput question="Vivo en la calle" _onChange={handleInputChange} inputs={inputs} placeholder="Miguel Hidalgo" name="calle" required={true} error={errors.calle} />
                        <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="número exterior" _onChange={handleInputChange} inputs={inputs} placeholder="1534" name="num_ext" required={true} error={errors.num_ext} />
                      </div>
                      <div className={maintenanceStyles.several_questions_flex}>
                        <QuestionInput question="y número interior" _onChange={handleInputChange} inputs={inputs} placeholder="15" name="num_int" error={errors.num_int} />
                        <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="en la colonia" _onChange={handleInputChange} inputs={inputs} placeholder="Independencia" name="colonia" required={true} error={errors.colonia} />
                      </div>
                    </div>
                  </>
                )
              case "desktopMin":
                return (
                  <>
                    <div className={maintenanceStyles.step1__container}>
                      <QuestionButton _onChange={handleInputChange} inputs={inputs} name="servicio" dropdownList={services} question="Solicito contacto para:" required={true} /> 
                      <div className={maintenanceStyles.several_questions_flex}>
                        <QuestionInput question="Mi nombre es" _onChange={handleInputChange} inputs={inputs} placeholder="Miguel Angel" name="nombre" required={true} error={errors.nombre} />
                        <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="y mis apellidos" _onChange={handleInputChange} inputs={inputs} placeholder="Medina Calvillo" name="apellidos" required={true} error={errors.apellidos} />
                      </div>
                      <div className={maintenanceStyles.several_questions_grid}>
                        <QuestionInput question="Vivo en la calle" _onChange={handleInputChange} inputs={inputs} placeholder="Miguel Hidalgo" name="calle" required={true} error={errors.calle} />
                        <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="número exterior" _onChange={handleInputChange} inputs={inputs} placeholder="1534" name="num_ext" required={true} error={errors.num_ext} />
                      </div>
                      <div className={maintenanceStyles.several_questions_flex}>
                        <QuestionInput question="y número interior" _onChange={handleInputChange} inputs={inputs} placeholder="15" name="num_int" error={errors.num_int} />
                        <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="en la colonia" _onChange={handleInputChange} inputs={inputs} placeholder="Independencia" name="colonia" required={true} error={errors.colonia} />
                      </div>
                    </div>
                  </>
                )
              case "desktop":
                return (
                  <>
                    <div className={maintenanceStyles.step1__container}>
                        <div className={ `${maintenanceStyles.several_questions_flex}`}>
                           <QuestionButton _classContainer={'dropdown_contact_options'} _onChange={handleInputChange} inputs={inputs} name="servicio" dropdownList={services} question="Solicito contacto para" required={true} />
                           <QuestionInput isContact={true} _classContainer={'container_info_right_contact'} question="Mi nombre es" _onChange={handleInputChange} inputs={inputs} placeholder="Miguel Angel" name="nombre" required={true} error={errors.nombre} />
                        </div>
                        <div className={maintenanceStyles.several_questions_flex}>
                             <QuestionInput  isContact={true} _classContainer={'container_info_left_contact'} question="y mis apellidos" _onChange={handleInputChange} inputs={inputs} placeholder="Medina Calvillo" name="apellidos" required={true} error={errors.apellidos} /> 
                             <QuestionInput isContact={true} _classContainer={'container_info_right_contact'} question="Vivo en la calle" _onChange={handleInputChange} inputs={inputs} placeholder="Miguel Hidalgo" name="calle" required={true} error={errors.calle} />
                        </div>
                        <div className={maintenanceStyles.several_questions_grid_3}>
                          <QuestionInput question="número exterior" _onChange={handleInputChange} inputs={inputs} placeholder="1534" name="num_ext" required={true} error={errors.num_ext} />
                          <QuestionInput question="y número interior" _onChange={handleInputChange} inputs={inputs} placeholder="15" name="num_int" error={errors.num_int} />
                          <QuestionInput question="en la colonia" _onChange={handleInputChange} inputs={inputs} placeholder="Independencia" name="colonia" required={true} error={errors.colonia} />
                      </div>
                    </div>
                  </>
                )
                case "xlg":
                  return (
                    <>
                      <div className={maintenanceStyles.step1__container}>
                          <div className={ `${maintenanceStyles.several_questions_flex}`}>
                             <QuestionButton _classContainer={'dropdown_contact_options'} _onChange={handleInputChange} inputs={inputs} name="servicio" dropdownList={services} question="Solicito contacto para" required={true} />
                             <QuestionInput isContact={true} _classContainer={'container_info_right_contact'} question="Mi nombre es" _onChange={handleInputChange} inputs={inputs} placeholder="Miguel Angel" name="nombre" required={true} error={errors.nombre} />
                          </div>
                          <div className={maintenanceStyles.several_questions_flex}>
                               <QuestionInput  isContact={true} _classContainer={'container_info_left_contact'} question="y mis apellidos" _onChange={handleInputChange} inputs={inputs} placeholder="Medina Calvillo" name="apellidos" required={true} error={errors.apellidos} /> 
                               <QuestionInput isContact={true} _classContainer={'container_info_right_contact'} question="Vivo en la calle" _onChange={handleInputChange} inputs={inputs} placeholder="Miguel Hidalgo" name="calle" required={true} error={errors.calle} />
                          </div>
                          <div className={maintenanceStyles.several_questions_grid_3}>
                            <QuestionInput question="número exterior" _onChange={handleInputChange} inputs={inputs} placeholder="1534" name="num_ext" required={true} error={errors.num_ext} />
                            <QuestionInput question="y número interior" _onChange={handleInputChange} inputs={inputs} placeholder="15" name="num_int" error={errors.num_int} />
                            <QuestionInput question="en la colonia" _onChange={handleInputChange} inputs={inputs} placeholder="Independencia" name="colonia" required={true} error={errors.colonia} />
                        </div>
                      </div>
                    </>
                  )
              default:
                return null
            }

            case 1:
              switch (device) {
                case "mobile":
                  return (
                    <>
                      <div className={maintenanceStyles.step1__container}>
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Ciudad:" placeholder="Saltillo" name="ciudad" required={true} error={errors.ciudad} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Estado:" placeholder="Coahuila" name="estado" required={true} error={errors.estado} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Código postal:" placeholder="25000" name="codigo_postal" required={true} error={errors.codigo_postal} />
                      <p className={maintenanceStyles.contact__title}>Para comunicarse conmigo:</p>
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Correo electrónico:" placeholder="miguel_medina@correo.com" name="email" required={true} type="email" error={errors.email} />
                      <QuestionInput _onChange={handleInputChange} inputs={inputs} _className="left_text" question="Número:" placeholder="844 63 87 033" name="phone" required={true} error={errors.phone} maxlength="10" />
                      <div className={maintenanceStyles.comments__container}>
                          <label className={maintenanceStyles.comments_label} htmlFor="mensaje">
                            Y estos son mis comentarios:
                          </label>
                          <textarea onChange={handleInputChange} className={maintenanceStyles.comments} rows="8" id="mensaje" name="mensaje" placeholder="Mis comentarios..." required />
                          <p className={underlinedStyle.warning__required}>{errors.mensaje ? "*Campo obligatorio" : null}</p>
                      </div>
                      </div>
                    </>
                  )
                case "tablet":
                  return (
                    <>
                      <div className={maintenanceStyles.step1__container}>
                          <QuestionInput question="Ciudad" _onChange={handleInputChange} inputs={inputs} placeholder="Saltillo" name="ciudad" required={true} error={errors.ciudad} />
                          <div className={maintenanceStyles.several_questions_flex}>
                            <QuestionInput question="Estado" _onChange={handleInputChange} inputs={inputs} placeholder="Coahuila" name="estado" required={true} error={errors.estado} />
                            <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="Codigó postal" _onChange={handleInputChange} inputs={inputs} placeholder="25000" name="codigo_postal" required={true} error={errors.codigo_postal} />
                          </div>
                          <QuestionInput question="Para comunicarse conmigo escribir al correo electrónico:" _onChange={handleInputChange} inputs={inputs} placeholder="miguel_medina@correo.com" name="email" required={true} type="email" error={errors.email} />
                          <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="o llamar al número:" _onChange={handleInputChange} inputs={inputs} placeholder="844 63 87 033" name="phone" required={true} error={errors.phone} maxlength="10" />
                          <div className={maintenanceStyles.several_questions_flex}>
                            <label className={maintenanceStyles.comments_label_contact} htmlFor="mensaje">
                                Y estos son mis comentarios:
                            </label> 
                          </div>
                          <div className={maintenanceStyles.comments__container}>
                            <textarea onChange={handleInputChange} className={maintenanceStyles.comments} rows="3" id="mensaje" name="mensaje" placeholder="Mis comentarios..." required />
                        </div>
                      </div>
                    </>
                  )
                case "desktopMin":
                  return (
                    <>
                      <div className={maintenanceStyles.step1__container}>
                        <div className={maintenanceStyles.several_questions_flex}>
                          <QuestionInput question="Ciudad" _onChange={handleInputChange} inputs={inputs} placeholder="Saltillo" name="ciudad" required={true} error={errors.ciudad} />
                          <QuestionInput question="Estado" _onChange={handleInputChange} inputs={inputs} placeholder="Coahuila" name="estado" required={true} error={errors.estado} />
                          <QuestionInput _classContainer={maintenanceStyles.flex__grow} question="código postal" _onChange={handleInputChange} inputs={inputs} placeholder="25000" name="codigo_postal" required={true} error={errors.codigo_postal} />
                        </div>
                        <QuestionInput question="Para comunicarse conmigo escribir al correo electrónico:" _onChange={handleInputChange} inputs={inputs} placeholder="miguel_medina@correo.com" name="email" required={true} type="email" error={errors.email} />
                        <QuestionInput question="o llamar el número:" _onChange={handleInputChange} inputs={inputs} placeholder="844 63 87 033" name="phone" required={true} error={errors.phone} maxlength="10" />
                        <QuestionInput question="Y estos son mis comentarios:" _onChange={handleInputChange} inputs={inputs} id="mensaje" name="mensaje" placeholder="Mis comentarios..." required={true} error={errors.mensaje} />
                      </div>
                    </>
                  )
                case "desktop":
                  return (
                    <>
                      <div className={maintenanceStyles.step1__container}>
                        <div className={` ${maintenanceStyles.several_questions_flex} `}>
                            <QuestionInput _classContainer={maintenanceStyles.flex__grow_nopadding} question="De la ciudad" _onChange={handleInputChange} inputs={inputs} placeholder="Saltillo" name="ciudad" required={true} error={errors.ciudad} />
                            <QuestionInput _classContainer={maintenanceStyles.input__padding} question="En el estado" _onChange={handleInputChange} inputs={inputs} placeholder="Coahuila" name="estado" required={true} error={errors.estado} />
                            <QuestionInput _classContainer={maintenanceStyles.input__padding} question="con C.P." _onChange={handleInputChange} inputs={inputs} placeholder="25000" name="codigo_postal" required={true} error={errors.codigo_postal} />
                        </div>
                        <div className={maintenanceStyles.several_questions_flex}>
                            <QuestionInput _classContainer={maintenanceStyles.flex__grow_nopadding} question="Para comunicarse conmigo, escribir al correo" _onChange={handleInputChange} inputs={inputs} placeholder="miguel_medina@correo.com" name="email" required={true} type="email" error={errors.email} />
                            <QuestionInput _classContainer={maintenanceStyles.input__padding} question="o llamar el número" _onChange={handleInputChange} inputs={inputs} placeholder="844 63 87 033" name="phone" required={true} error={errors.phone} maxlength="10" />
                        </div>
                        <QuestionInput question="Y estos son mis comentarios:" _onChange={handleInputChange} inputs={inputs} id="mensaje" name="mensaje" placeholder="Mis comentarios..." required={true} error={errors.mensaje} />
                      </div>
                    </>
                  )
                  case "xlg":
                    return (
                      <>
                        <div className={maintenanceStyles.step1__container}>
                          <div className={` ${maintenanceStyles.several_questions_flex} `}>
                              <QuestionInput _classContainer={maintenanceStyles.flex__grow_nopadding} question="De la ciudad" _onChange={handleInputChange} inputs={inputs} placeholder="Saltillo" name="ciudad" required={true} error={errors.ciudad} />
                              <QuestionInput _classContainer={maintenanceStyles.input__padding} question="En el estado" _onChange={handleInputChange} inputs={inputs} placeholder="Coahuila" name="estado" required={true} error={errors.estado} />
                              <QuestionInput _classContainer={maintenanceStyles.input__padding} question="con C.P." _onChange={handleInputChange} inputs={inputs} placeholder="25000" name="codigo_postal" required={true} error={errors.codigo_postal} />
                          </div>
                          <div className={maintenanceStyles.several_questions_flex}>
                              <QuestionInput _classContainer={maintenanceStyles.flex__grow_nopadding} question="Para comunicarse conmigo, escribir al correo" _onChange={handleInputChange} inputs={inputs} placeholder="miguel_medina@correo.com" name="email" required={true} type="email" error={errors.email} />
                              <QuestionInput _classContainer={maintenanceStyles.input__padding} question="o llamar el número" _onChange={handleInputChange} inputs={inputs} placeholder="844 63 87 033" name="phone" required={true} error={errors.phone} maxlength="10" />
                          </div>
                          <QuestionInput question="Y estos son mis comentarios:" _onChange={handleInputChange} inputs={inputs} id="mensaje" name="mensaje" placeholder="Mis comentarios..." required={true} error={errors.mensaje} />
                        </div>
                      </>
                    )
                default:
                  return null
              }
        
          default:
            break;
        }

  }

  function getNextButton() {
    switch (step) {
      case 0:
        return (
          <div className={maintenanceStyles.next__button_container}>
            <ButtonSemicircle _onClick={handleNextStep} _text="Continuar" _className={maintenanceStyles.next__button} />
          </div>
        )
      case 1:
        return (
          <div className={maintenanceStyles.next__button_container}>
            <ButtonArrow _classIcon="icon_maintenance" _section="maintenance" _textClass={maintenanceStyles.back__button_text} _className={`${maintenanceStyles.back__button_tablet} ${maintenanceStyles.back__button_desktop}`} _text="Volver" _onClick={handleBackStep} _position={POSITION.BEFORE} _type={TYPES.PREV} />
            <ButtonSemicircle _onClick={handlerClickSend} _text="Enviar" _className={maintenanceStyles.next__button} />
          </div>
        )
      default :
        break
    }
  }

  return (
    <Layout>
      <div className={maintenanceStyles.maintenance__container}>
          <>
          {
            step === 0 ? null :
            <ButtonArrow _textClass={maintenanceStyles.back__button_text} _className={`${maintenanceStyles.back__button} ${maintenanceStyles.back__button_mobile}`} _text="Volver" _onClick={handleBackStep} _position={POSITION.BEFORE} _type={TYPES.PREV} />
          }
           
            <MaintenanceTitle getStepText={getStepText} contact={true}  step={step} title_text="Contáctanos" />
            <div className={maintenanceStyles.form__container}>
              <form noValidate id="maintenance__form" className={maintenanceStyles.maintenance__form} method="post" action="/enviado" onSubmit={handleSubmit}>
                {getQuestions()}
                <button id="submit__button" type="submit" className={maintenanceStyles.submit__button} />
              </form>
            </div>
            {getNextButton()}
          </>
      </div>
    </Layout>
  )
}

export default Maintenance
